import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ShorthandGrid from '../components/shorthand/shorthandGrid'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const ShorthandLandingPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle =
    'أبرز المقالات والقصص الإخبارية والتحقيقات الصحفية | الشرق للأخبار '
  const seoDescription =
    'تمنحكم الشرق للإخبار منظوراً آخر للأحداث الحالية والتاريخية من خلال المقالات والقصص الإخبارية والتحقيقات الصحفية الخاصة. اكتشف آخر المستجدات حول العالم الآن.'

  const canonicalUrl = `${process.env.GATSBY_BASE_URL}specials/`

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <>
      <Layout
        location={location}
        title={siteTitle}
        lang={'ar'}
        layoutType="fullWidth"
      >
        <h1 className="customH1">
          {'أبرز التغطيات والقصص الإخبارية والتحقيقات الصحفية '}
        </h1>
        <SEO
          title={seoTitle}
          lang="ar"
          description={seoDescription}
          canonicalUrl={canonicalUrl}
        />
        <ShorthandGrid />
      </Layout>
    </>
  )
}

export default ShorthandLandingPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
