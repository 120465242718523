import React from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import isEmpty from 'lodash/isEmpty'
import loadable from '@loadable/component'

import ShorthandGridCard from './shorthandGridCard'
import testMobile from '../../utils/testMobile'
import { sendEventItemClick } from '../../utils/sendEvent'
import {
  GridLoader,
  Spinner,
  GridError,
  Header,
  BannerGrid,
  HeaderText,
  GridContainer,
  ArticleCardRow,
  NoData
} from './shorthand.styled'

const AdHolder = loadable(() => import('../holder'))

class ShorthandGrid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isBrowser: false,
      isMobile: false
    }
  }
  componentDidMount() {
    const isMobile = testMobile()
    this.setState({
      isBrowser: true,
      isMobile
    })

    // fetch page 0 data
    this.fetchData(0)

    window.addEventListener('resize', this.handleResize, true)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)

    // reset already fetch articles
    this.props.resetShorthandArticles()
  }

  handleResize = () => {
    const isMobile = testMobile()
    this.setState({
      isMobile
    })
  }

  fetchData = (
    page = this.props.shorthandArticles ? this.props.shorthandArticles.page : 0
  ) => {
    this.props.fetchShorthandArticles({ page, lang: 'ar' })
  }

  renderLeaderboardAd = () => {
    const { isBrowser, isMobile } = this.state

    if (!isMobile && isBrowser) {
      return (
        <>
          <BannerGrid
            data-page-name={'shorthandLanding'}
            className="topBanner"
            position="top"
          >
            <AdHolder
              page={'shorthandLanding'}
              place="top"
              type={'leaderBoard'}
            />
          </BannerGrid>
        </>
      )
    }

    return null
  }

  renderHeader = () => {
    return (
      <Header>
        <HeaderText>{'ملفات خاصة'}</HeaderText>
      </Header>
    )
  }

  renderMainArticle = () => {
    const { shorthandArticles } = this.props

    if (shorthandArticles && !isEmpty(shorthandArticles.data)) {
      const mainArticle = shorthandArticles.data[0]
      return <ShorthandGridCard shorthandArticle={mainArticle} isMainArticle />
    }

    return null
  }

  render() {
    const { shorthandArticles } = this.props

    if (shorthandArticles) {
      if (!shorthandArticles.isFetching && shorthandArticles.isError) {
        return (
          <GridError>
            Unexpected Error - Please reload this page to continue
          </GridError>
        )
      }

      return (
        <div>
          {this.renderLeaderboardAd()}
          {this.renderHeader()}
          <GridContainer>
            {this.renderMainArticle()}
            <ArticleCardRow>
              <InfiniteScroll
                key="shorthandGridScroll"
                dataLength={shorthandArticles.data.length}
                next={() => {
                  if (shorthandArticles.page > 0) {
                    this.fetchData()
                  }
                }}
                hasMore={shorthandArticles.hasMore}
                loader={
                  shorthandArticles.isFetching && (
                    <GridLoader>
                      <Spinner />
                    </GridLoader>
                  )
                }
                endMessage={
                  shorthandArticles.data.length === 0 && (
                    <NoData>
                      <p>لايوجد معروضات</p>
                    </NoData>
                  )
                }
              >
                {shorthandArticles.data
                  .slice(1)
                  .map((shorthandArticle, index) => (
                    <ShorthandGridCard
                      shorthandArticle={shorthandArticle}
                      key={`shorthand-grid-article-${index}`}
                      index={index}
                      onClick={() => {
                        sendEventItemClick({
                          itemId: shorthandArticle.id,
                          pos: {
                            column: index + 1,
                            row: index + 1,
                            category: 'Shorthand'
                          },
                          itemType: 'article'
                        })
                        dataLayer.push({
                          event: 'asEvent',
                          analyticsEventLabel: 'Navigation',
                          eventCategory: 'Navigation',
                          eventAction: 'Headline - Click',
                          eventLabel: shorthandArticle.title || 'Shorthand',
                          publish_date: `${shorthandArticle.publishedAt}`
                        })
                      }}
                      onMouseEnter={() => {
                        // GTM event for hover
                        dataLayer.push({
                          event: 'asEvent',
                          analyticsEventLabel: 'Navigation',
                          eventCategory: 'Navigation',
                          eventAction: 'Headline - Hover',
                          eventLabel: shorthandArticle.title || 'Shorthand',
                          publish_date: `${shorthandArticle.publishedAt}`
                        })
                      }}
                    />
                  ))}
              </InfiniteScroll>
            </ArticleCardRow>
          </GridContainer>
        </div>
      )
    }

    return null
  }
}

const MemoizedShorthandGrid = React.memo(ShorthandGrid)

const mapStateToProps = ({ shorthandArticles }) => {
  return {
    shorthandArticles
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShorthandArticles: (payload) => {
      dispatch({ type: 'FETCH_SHORTHAND_ARTICLES', payload })
    },
    resetShorthandArticles: () => {
      dispatch({ type: 'RESET_SHORTHAND_ARTICLES' })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemoizedShorthandGrid)
