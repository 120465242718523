import DateTimeService from './common'
import testMobile from './testMobile'
import isEmpty from 'lodash/isEmpty'
import { parseISO } from 'date-fns'

const defaultArticleData = {
  content_id: '',
  content_type: '',
  content_year: '',
  content_length: '',
  content_language: '',
  content_audience: 'regular',
  content_premium: 'no',
  publish_date: '',
  content_author_id: '',
  content_author_name: '',
  content_category_name: '',
  content_category_id: '',
  content_title: ''
}

const getHashCode = (str) => {
  let hash = 0
  let i = 0
  let chr = ''

  if (!str) return ''

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash.toString()
}

/**
 * Get User data from local storage
 * If user has not logged-in, then return empty default object
 * @returns user object
 */
const getUserDetails = () => {
  let userData = {
    user_id: '',
    user_level: 'standard',
    user_email: '',
    user_age: '',
    user_gender: '',
    user_family_status: '',
    user_member_since: '',
    user_last_visit:
      localStorage && localStorage.getItem('user_last_visit')
        ? localStorage.getItem('user_last_visit')
        : '',
    browser_id:
      localStorage && localStorage.getItem('fingerprint')
        ? localStorage.getItem('fingerprint')
        : '',
    logged_in: 'no'
  }
  if (
    localStorage &&
    localStorage.getItem('userType') !== 'anonymous' &&
    localStorage.getItem('token') &&
    localStorage.getItem('user')
  ) {
    const user = JSON.parse(localStorage.getItem('user'))
    var userEmailArr = user.email ? user.email.split('@') : []
    var hashedEmail =
      userEmailArr && userEmailArr.length > 1
        ? getHashCode(userEmailArr[0]) + '@' + userEmailArr[1]
        : null

    return {
      user_id: user.id || '',
      user_level: 'standard',
      user_email: hashedEmail ? hashedEmail : '',
      user_age: user.ageRange || '',
      user_gender: user.gender || '',
      user_family_status: user.maritalStatus || '',
      user_last_visit: localStorage.getItem('user_last_visit') || '',
      user_member_since: user.createdAt
        ? DateTimeService.formatInLocalTime(user.createdAt, 'yyyy-MM-dd')
        : '',
      logged_in: 'yes'
    }
  }
  return userData
}

export const createArticleData = (article) => {
  if (!article) {
    return {}
  }

  try {
    const articleDate =
      article.publishedAt || article.updatedAt || article.createdAt

    let articleYear = ''
    const parsedData = DateTimeService.parseISOIfValid(articleDate)

    if (parsedData && parsedData.getFullYear) {
      articleYear = parsedData.getFullYear() || ''
    }

    const contentAuthor = !isEmpty(article.authors)
      ? {
          content_author_id: article.authors[0].id ? article.authors[0].id : '',
          content_author_name: article.authors[0].name
            ? article.authors[0].name
            : ''
        }
      : {}

    const articleCategories = !isEmpty(article.articleCategories)
      ? {
          content_category_name: article.articleCategories[0].name
            ? article.articleCategories[0].name.ar
            : '',
          content_category_id: article.articleCategories[0].id
            ? article.articleCategories[0].id
            : ''
        }
      : {}

    const dataLayerArticleVariables = {
      content_id: `${article.id}`,
      content_type: article.type || 'article',
      content_year: articleYear,
      content_length: article.contentAr ? article.contentAr.length : 0,
      content_language: article.lang || 'ar',
      content_audience: 'regular',
      content_premium: 'no',
      publish_date: DateTimeService.formatInLocalTime(
        articleDate,
        'yyyy-MM-dd'
      ),
      analyticsEventLabel: 'article',
      content_title: article.title
    }

    return {
      ...dataLayerArticleVariables,
      ...contentAuthor,
      ...articleCategories
    }
  } catch (error) {
    console.log('Error while creating article Page data', error)
    return {}
  }
}

/**
 * Function to push page_data event in data layer when user visits any page in the website
 * add article data if its article/headline page, else it will be empty string
 * add user data if user is logged-in, else it will be empty string
 * @param {*} article
 */
export const addGoogleAnalyticsPageData = (article = {}) => {
  try {
    window.dataLayer = window.dataLayer || []
    const isMobile = testMobile()
    const user = getUserDetails()

    const articleData = { ...defaultArticleData, ...article }

    window.dataLayer.push({
      event: 'page_data',
      platform_name: 'asharq_web',
      platform_mobile: isMobile ? 'yes' : 'no',
      analyticsEventLabel: 'page_data',
      ...user,
      ...articleData
    })
  } catch (error) {
    console.log('Error while adding google analytics page data event', error)
  }
}
