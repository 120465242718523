import React, { useRef, useState, useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import DateTimeService from '../../utils/common'
import loadable from '@loadable/component'
const Ellipsis = loadable(() => import('react-ellipsis-pjs'))

import ArticleImage from './articleImage'
import {
  MainArticleContainer,
  MainArticleTextContainer,
  MainArticleText,
  MainArticleSubText,
  GridArticleCardContainer,
  GridArticleTextContainer,
  GridArticleText,
  GridArticleSubText
} from './shorthand.styled'
import { sendEventItemClick } from '../../utils/sendEvent'

const calculateLines = (containerRef, mainArticleSubTextRef) => {
  const node = containerRef.current
  const subTextNode = mainArticleSubTextRef.current

  if (node) {
    const subTextHeight =
      subTextNode && subTextNode.offsetHeight ? subTextNode.offsetHeight : 30

    const paddingTop = parseInt(getComputedStyle(node).paddingTop) || 0
    const paddingBottom = parseInt(getComputedStyle(node).paddingBottom) || 0
    const mainArticleContainerHeight =
      node.offsetHeight - paddingTop - paddingBottom
    const mainArticleTextHeight = mainArticleContainerHeight - subTextHeight
    const rows = Math.floor(mainArticleTextHeight / 60) // 60 is the line height

    return rows
  }

  return 3
}

const ShorthandGridCard = ({
  shorthandArticle,
  isMainArticle = false,
  index = 0
}) => {
  const mainArticleTextContainerRef = useRef(null)
  const mainArticleSubTextRef = useRef(null)
  const [mainArticleLines, setMainArticleLines] = useState(3)

  useEffect(() => {
    if (mainArticleTextContainerRef && mainArticleTextContainerRef.current) {
      setMainArticleLines(
        calculateLines(mainArticleTextContainerRef, mainArticleSubTextRef)
      )
    }
  }, [mainArticleTextContainerRef])

  if (isMainArticle) {
    return (
      <MainArticleContainer>
        <a
          href={`/specials/${shorthandArticle.slug}`}
          onClick={() => {
            sendEventItemClick({
              itemId: shorthandArticle.id,
              pos: {
                column: 1,
                row: 1,
                category: 'Shorthand'
              },
              itemType: 'article'
            })
            dataLayer.push({
              event: 'asEvent',
              analyticsEventLabel: 'Navigation',
              eventCategory: 'Navigation',
              eventAction: 'Headline - Click',
              eventLabel: shorthandArticle.title || 'Shorthand',
              publish_date: `${shorthandArticle.publishedAt}`
            })
          }}
          onMouseEnter={() => {
            // GTM event for hover
            dataLayer.push({
              event: 'asEvent',
              analyticsEventLabel: 'Navigation',
              eventCategory: 'Navigation',
              eventAction: 'Headline - Hover',
              eventLabel: shorthandArticle.title || 'Shorthand',
              publish_date: `${shorthandArticle.publishedAt}`
            })
          }}
        >
          <MainArticleTextContainer ref={mainArticleTextContainerRef}>
            <MainArticleText>
              <Ellipsis
                lines={mainArticleLines}
                text={shorthandArticle.title}
                key={mainArticleLines}
              />
            </MainArticleText>

            <MainArticleSubText ref={mainArticleSubTextRef}>
              <time
                dateTime={DateTimeService.formattedDateTime(
                  shorthandArticle.updatedAt || shorthandArticle.publishedAt
                )}
              >
                {DateTimeService.formatInLocalTime(
                  shorthandArticle.updatedAt || shorthandArticle.publishedAt,
                  'dd MMMM yyyy'
                )}
              </time>
            </MainArticleSubText>
          </MainArticleTextContainer>
          <ArticleImage
            imageSrc={shorthandArticle.mainImageUrl}
            imageMeta={shorthandArticle.mainImage}
            isMainImage
          />
        </a>
      </MainArticleContainer>
    )
  }

  /**
   * Dont use gatsby's Link component since Link component will try to preload the page and
   * because of it the shorthand script was not loading properly instead use default anchor tag
   * */
  return (
    <GridArticleCardContainer cardColumn={index + 1} data-column={index + 1}>
      <a
        href={`/specials/${shorthandArticle.slug}`}
        onClick={() => {
          sendEventItemClick({
            itemId: shorthandArticle.id,
            pos: {
              column: index + 1,
              row: index + 1,
              category: 'Shorthand'
            },
            itemType: 'article'
          })
          dataLayer.push({
            event: 'asEvent',
            analyticsEventLabel: 'Navigation',
            eventCategory: 'Navigation',
            eventAction: 'Headline - Click',
            eventLabel: shorthandArticle.title || 'Shorthand',
            publish_date: `${shorthandArticle.publishedAt}`
          })
        }}
        onMouseEnter={() => {
          // GTM event for hover
          dataLayer.push({
            event: 'asEvent',
            analyticsEventLabel: 'Navigation',
            eventCategory: 'Navigation',
            eventAction: 'Headline - Hover',
            eventLabel: shorthandArticle.title || 'Shorthand',
            publish_date: `${shorthandArticle.publishedAt}`
          })
        }}
      >
        <LazyLoad once>
          <ArticleImage
            imageSrc={shorthandArticle.mainImageUrl}
            imageMeta={shorthandArticle.mainImage}
          />
          <GridArticleTextContainer>
            <GridArticleText>
              <Ellipsis lines={3} text={shorthandArticle.title} />
            </GridArticleText>
            <GridArticleSubText>
              <time
                dateTime={DateTimeService.formattedDateTime(
                  shorthandArticle.updatedAt || shorthandArticle.publishedAt
                )}
              >
                {DateTimeService.formatInLocalTime(
                  shorthandArticle.updatedAt || shorthandArticle.publishedAt,
                  'dd MMMM yyyy'
                )}
              </time>
            </GridArticleSubText>
          </GridArticleTextContainer>
        </LazyLoad>
      </a>
    </GridArticleCardContainer>
  )
}

export default React.memo(ShorthandGridCard)
