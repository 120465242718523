import React from 'react'
import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const GridLoader = styled.div`
  display: flex;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #ccc;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

export const Spinner = (props) => (
  <StyledSpinner viewBox="0 0 50 50" style={{ ...(props.inlineProps || '') }}>
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
)

export const GridError = styled.div`
  text-align: center;
  color: #666;
  padding: 20px 0;
`
export const Header = styled.div`
  color: #ffffff;
  width: 100%;
  height: 124px;
  background: #ffffff;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-family: Neue Haas Grotesk Bloomberg;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    height: 117px;
    margin-bottom: 0;
  }
`

export const BannerGrid = styled.div`
  text-align: center;

  @media ${device.s} {
    min-height: 280px;
    overflow: hidden;
    margin-bottom: 6px;
    margin-top: 0px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 6px;
    grid-auto-rows: 16vw;
    position: relative;

    &[data-page-name='category'] {
      display: block;
    }

    &.grid-row {
      margin: 0;
    }
  }

  &.topBanner {
    margin-bottom: 30px;
  }

  &.banner-grid-row {
    padding: 40px 0 25px 0;
  }

  &.topRow {
    padding-top: 30px;
    margin-top: -70px;
    margin-bottom: ${({ position }) => (position === 'top' ? 0 : '70px')};
  }
`
export const HeaderText = styled.span`
  color: #333333;
  opacity: 1;
  font-size: 36px;
  font-family: Neue Haas Grotesk Bloomberg;
  padding: 0 10px 10px;
  font-weight: 900;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    font-size: 28px;
  }
`
export const SubText = styled.span`
  color: #939393;
  opacity: 1;
  font-size: 18px;
  font-family: Neue Haas Grotesk Bloomberg;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    font-size: 16px;
  }
`
export const GridContainer = styled.div`
  max-width: 1300px;
  margin: 0 auto 50px;
  padding: 0 25px;
  position: relative;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    margin: 0 auto 30px;
  }
`
export const ArticleCardRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    flex-direction: column;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .infinite-scroll-component {
    width: 100%;
    overflow: hidden !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media ${device.s}, ${device.isSmallDeviceLandscape} {
      flex-direction: column;
    }
  }
`
export const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const MainArticleContainer = styled.div`
  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    margin: 0px -25px 28px;
  }

  a {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 568px;
    text-decoration: none;

    @media ${device.s}, ${device.isSmallDeviceLandscape} {
      flex-direction: column-reverse;
      height: 466px;
    }
  }
`

export const MainArticleImageContainer = styled.div`
  width: 58%;
  height: 568px;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    width: 100%;
    height: 280px;
  }
`

export const MainArticleTextContainer = styled.div`
  width: 42%;
  height: 568px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    width: 100%;
    height: 186px;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`

export const MainArticleText = styled.div`
  color: #333333;
  opacity: 1;
  font: normal normal bold 40px/65px Neue Haas Grotesk Bloomberg;
  text-align: right;
  padding: 10px 40px 0px;
  display: flex;
  max-height: 90%;
  line-height: 60px;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    padding: 0 18px;
    font: normal normal bold 30px/40px Neue Haas Grotesk Bloomberg;
    align-items: center;
  }
`

export const MainArticleSubText = styled.div`
  color: #333333;
  font: normal normal normal 21px/34px Neue Haas Grotesk Bloomberg;
  opacity: 1;

  padding: 0 40px;
  height: 10%;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    padding: 0 18px;
    font: normal normal normal 15px/25px Neue Haas Grotesk Bloomberg;

    margin: 18px 0;
    height: 10%;
  }
`
export const GridArticleCardContainer = styled.div`
  display: flex;
  height: 414px;
  text-decoration: none;
  flex-direction: column;
  width: ${({ cardColumn }) =>
    cardColumn && cardColumn % 3 === 0
      ? `calc(100% / 3)`
      : `calc(100% / 3 - 20px)`};
  flex: 0 1 auto;
  margin: ${({ cardColumn }) =>
    cardColumn && cardColumn % 3 === 0
      ? `40px 0px 0px 0px`
      : `40px 0px 0px 20px`};

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    max-height: 370px;
    height: 100%;
    width: 100%;
    margin: 0px 0px 35px;
  }
`
export const GridArticleImageContainer = styled.div`
  width: 100%;
  height: 250px;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    height: 238px;
  }

  img {
    border-radius: 0px 12px;
  }
`
export const GridArticleTextContainer = styled.div`
  width: 100%;
  height: 154px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    width: 100%;
    max-height: 132px;
    height: auto;
    margin-top: 14px;
  }
`
export const GridArticleText = styled.div`
  color: #333333;
  opacity: 1;
  max-height: 114px;
  font: normal normal bold 25px/37px Neue Haas Grotesk Bloomberg;
  text-align: right;
  height: 100%;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    max-height: 106px;
    font: normal normal bold 25px/35px Neue Haas Grotesk Bloomberg;
  }
`
export const GridArticleSubText = styled.div`
  color: #333333;
  font: normal normal normal 18px/29px Neue Haas Grotesk Bloomberg;
  opacity: 1;
  padding-top: 10px;

  @media ${device.s}, ${device.isSmallDeviceLandscape} {
    font: normal normal normal 16px/26px Neue Haas Grotesk Bloomberg;
  }
`
