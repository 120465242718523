import React from 'react'
import useDimensions from 'react-use-dimensions'

import signImgix from '../../utils/signImgix'
import {
  MainArticleImageContainer,
  GridArticleImageContainer
} from './shorthand.styled'
import { isBrowser } from '../../constants/app'

const ArticleImage = ({
  imageSrc,
  isMainImage = false,
  imageMeta = {},
  title = ''
}) => {
  const [ref, { width: containerWidth, height: containerHeight }] = isBrowser()
    ? useDimensions()
    : []

  const getSignedImageUrl = () => {
    const { fpX3 = 1, fpY3 = 1, fpZ3 = 1 } = imageMeta

    return signImgix(
      `${imageSrc}?h=${containerHeight}&q=80&fit=crop&w=${containerWidth}&fp-x=${fpX3}&fp-y=${fpY3}&fp-z=${fpZ3}&crop=focalpoint`
    )
  }

  const ImageContainerComponent = isMainImage
    ? MainArticleImageContainer
    : GridArticleImageContainer

  return (
    <ImageContainerComponent ref={ref}>
      {containerWidth && containerHeight && (
        <img src={getSignedImageUrl()} alt={title} />
      )}
    </ImageContainerComponent>
  )
}

export default ArticleImage
